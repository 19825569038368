<template>
  <div>
    <div style="height: 5.625rem"></div>
    <!-- 创作 -->
    <div class="pt-50 pb-40 flex flex-column align-center justify-center bg-F1F2F3">
      <div class="font-26 text-222222 mb-20">创作</div>
      <div class="flex align-center">
        <div class="mr-15 cursorp" @click="goMycreation">
          <!-- <img
            src="@/assets/img/mywork.png"
            alt=""
            width="7.8125rem"
            height="2.1875rem"
          /> -->
          <el-image
            class="mr-10"
            style="width: 7.8125rem; height: 2.1875rem"
            :src="require('@/assets/img/mywork.png')"
            fit="cover"
          ></el-image>
        </div>
        <div class="cursorp addchuangzuo" @click="goupwork">
          加入创作联盟

          <!-- <img src="@/assets/img/upvideo.png" alt="" width="6.25rem" height="2.1875rem" /> -->
        </div>
      </div>
    </div>
    <!-- 用户内容 -->
    <div class="label flex align-center justify-between">
      <div class="flex align-center">
        <!-- <img :src="info.avatar" alt="" class="avatar" /> -->
        <el-image class="avatar" :src="info.avatar" fit="cover"></el-image>
        <div class="mr-55 font-20 font-weight-bolder">
          {{ info.username }}
        </div>
        <div class="mr-70">
          <!-- <img
            src="@/assets/img/fensi.png"
            width="1.875rem"
            height="1.625rem"
            alt=""
          /> -->
          <el-image
            style="width: 1.875rem; height: 1.625rem"
            :src="require('@/assets/img/fensi.png')"
            fit="cover"
          ></el-image>
          <span class="ml-10 text-222222">{{ info.fans_nums }}</span
          ><span class="ml-10 text-666666">粉丝</span>
        </div>
        <div class="mr-70">
          <!-- <img
            src="@/assets/img/huozan.png"
            alt=""
            width="1.6875rem"
            height="1.625rem"
          /> -->
          <el-image
            style="width: 1.6875rem; height: 1.625rem"
            :src="require('@/assets/img/huozan.png')"
            fit="cover"
          ></el-image>
          <span class="ml-10 text-222222">{{ info.fabulous_nums }}</span
          ><span class="ml-10 text-666666">获赞</span>
        </div>
        <div class="mr-70">
          <!-- <img
            src="@/assets/img/guanzhu.png"
            alt=""
            width="1.6875rem"
            height="1.625rem"
          /> -->
          <el-image
            style="width: 1.6875rem; height: 1.625rem"
            :src="require('@/assets/img/guanzhu.png')"
            fit="cover"
          ></el-image>
          <span class="ml-10 text-222222">{{ info.follow_nums }}</span
          ><span class="ml-10 text-666666">关注</span>
        </div>
      </div>
      <div
        class="flex align-center justify-center coll attention cursorp text-white"
        v-if="info.is_my != 1 && info.is_follow != 1"
        @click="follow"
      >
        <i class="el-icon-plus"></i>
        <div class="ml-5">关注</div>
      </div>
      <div
        class="flex align-center justify-center coll attention2 cursorp text-white"
        v-if="info.is_my != 1 && info.is_follow == 1"
        @click="follow"
      >
        <div class="ml-5">取消关注</div>
      </div>
    </div>
    <!-- 内容 -->
    <!-- <div class="container">
      <div class="waterfall">
        <my-waterfall
          :data="cardList"
          @loadmore="loadmore"
          @godetails="godetails"
        ></my-waterfall>
      </div>
      <div class="font-14 text-666666 mt-80 text-center">
        {{ bottomtext }}
      </div>
    </div> -->
    <div class="flex flex-wrap mt-45 px-88">
      <!-- mr-50 -->
      <div
        v-for="(item, index) in cardList"
        :key="index"
        class="mb-20 cursorp px-20"
        style="width: 20%; box-sizing: border-box"
        @click="godetails(item)"
      >
        <div class="bg-white">
          <el-image
            class="rounded3"
            style="height: 10.625rem; width: 100%"
            :src="item.img"
            fit="cover"
          ></el-image>
          <div class="p-15">
            <div class="item-desc">{{ item.title }}</div>
            <!-- <div class="flex align-center justify-between">
              <div class="flex align-center">
                <el-image
                  style="
                    border-radius: 50%;
                    width: 1.625rem;
                    height: 1.625rem;
                    margin-right: 0.5625rem;
                  "
                  :src="item.avatar"
                  fit="cover"
                ></el-image>
                <div class="name">{{ item.username }}</div>
              </div>
              <div class="flex align-center">
                <el-image
                  style="width: 1.125rem; height: 1rem; margin-right: 0.3125rem"
                  :src="require('@/assets/img/zan_act.png')"
                  fit="cover"
                  v-if="item.is_fabulous == 1"
                ></el-image>
                <el-image
                  style="width: 1.125rem; height: 1rem; margin-right: 0.3125rem"
                  :src="require('@/assets/img/zan.png')"
                  fit="cover"
                  v-else
                ></el-image>
                <div class="like-total">{{ item.fabulous_nums }}</div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="flex align-center justify-center py-20 border-top border-ligit-BFBFBF">
      <el-pagination
        background
        layout="prev, pager, next"
        prev-text="上一页"
        next-text="下一页"
        :page-count="page_nums"
        @current-change="currentChange"
        :current-page="page"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import req from "../../utils/req";
import myWaterfall from "@/components/myWaterfall2";
export default {
  components: { myWaterfall },
  data() {
    return {
      page_nums: 0,
      info: "",
      cardList: [],
      userId: "",
      bottomtext: "滑动加载更多~",
      page: 1,
      // userId: localStorage.getItem("uid"),
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        let userId = this.$route.query.userId;
        if (userId) {
          this.userId = userId;
          this.getdata();
        }
      },
      deep: true,
    },
  },
  mounted() {
    let userId = this.$route.query.userId;
    if (userId) {
      this.userId = userId;
      this.page = 1;
      this.getdata();
    }
  },
  methods: {
    currentChange(e) {
      console.log(e);
      this.page = e;
      this.getdata();
    },
    // 跳转上传作品
    goupwork() {
      // this.$router.push({
      //   path: "/upwork",
      //   query: { type: "upwork" },
      // });
      this.$router.push({
        path: "/richtext",
        query: {type: "richtext", foottype: 8 },
      });
    },
    // 关注
    follow() {
      let that = this;
      req
        .post("original/follow", {
          user_id: that.userId,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          that.$message.success(res.msg);
          this.page = 1;
          this.cardList = [];
          this.getdata();
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 跳转我的创作
    goMycreation() {
      this.$router.push({
        path: "/creationMy",
        query: { type: "creationMy" },
      });
    },
    // 跳转详情
    godetails(e) {
      // console.log(e);creationdetails
      // this.$router.push({
      //   path: "/creationdetails",
      //   query: { type: "creationdetails", moveid: e.id },
      // });
      this.$router.push({
        path: "/creationDetail2",
        query: { type: "creationDetail2", moveid: e.id },
      });
    },
    // 滚动底部加载更多
    loadmore() {
      console.log("滑动到底部~");
      this.page = this.page + 1;
      this.getdata();
    },

    // 获取数据
    getdata() {
      let that = this;
      req
        .post("original/userInfo", {
          user_id: this.userId,
          page: this.page,
        })
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          this.info = res.data;
          this.cardList = res.data.original.data;
          this.page_nums = res.data.original.last_page;
          // this.bottomtext = "滑动加载更多~";
          // if (this.page == 1) {
          //   this.cardList = res.data.original.data;
          // } else {
          //   if (res.data.original.data && res.data.original.data.length) {
          //     this.cardList = [...this.cardList, ...res.data.original.data];
          //   } else {
          //     this.bottomtext = "没有更多数据~";
          //   }
          // }
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.addchuangzuo {
  background: linear-gradient(0deg, #ff534d, #ff8f6d);
  border-radius: 6.25rem;
  padding: 0 0.9375rem;
  font-size: 0.875rem;
  line-height: 2.1875rem;
  color: #ffffff;
}
.container {
  background-color: #f1f2f3;
  // width: 100%;
  padding: 3.4375rem 5rem;
}
.label {
  padding: 1.75rem 5.5rem 2rem 8.1875rem;
  .avatar {
    width: 4.375rem;
    height: 4.375rem;
    border-radius: 50%;
    margin-right: 1.4375rem;
  }
  .attention {
    width: 6.5625rem;
    height: 2.1875rem;
    background: #ff5950;
    border-radius: 0.25rem;
  }
  .attention2 {
    width: 6.5625rem;
    height: 2.1875rem;
    background: #b7b7b7;
    border-radius: 0.25rem;
  }
}
</style>
